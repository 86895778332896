import axios from 'axios'


export default async function init(kernel) {
    kernel.log("Loading API");

    kernel.api = {}

    kernel.api.get = async (resource, opts = {}) => {
        const url = `${kernel.config.central}${resource}`
        const deviceToken = localStorage.getItem("deviceToken")

        kernel.debug(`GET ${resource} > ${url}`, ['api'])
        const options = {
            url: url,
            method: "GET",
            headers: {
                // "User-Agent": `DOHzel/${pack.version}`,
            }
        }
        if (opts.userRequest === true && kernel?.session?.current)
            options.headers.Authorization = kernel.session.current
        else if (opts.deviceRequest === true && deviceToken)
            options.headers.Authorization = deviceToken
        else if (kernel.api.token)
            options.headers.Authorization = kernel.api.token

        try {
            const ret = await axios(options)
            if (ret.data.error) {
                kernel.debug(`Network error: ${ret.data.error}`, ['api'])
            }

            return (ret.data)
        } catch (e) {
            return ({ error: e.message, data: options.data })
        }
    }

    kernel.api.stream = async (resource, opts = {}) => {
        const url = `${kernel.config.central}${resource}`
        const deviceToken = localStorage.getItem("deviceToken")

        kernel.debug(`STREAM ${resource} > ${url}`, ['api'])
        const options = {
            url: url,
            responseType: 'blob',
            method: "GET",
            headers: {
                // "User-Agent": `DOHzel/${pack.version}`,
            }
        }
        if (opts.userRequest === true && kernel?.session?.current)
            options.headers.Authorization = kernel.session.current
        else if (opts.deviceRequest === true && deviceToken)
            options.headers.Authorization = deviceToken
        else if (kernel.api.token)
            options.headers.Authorization = kernel.api.token

        try {
            const ret = await axios(options)
            if (!ret.data) {
                kernel.debug(`Network error: ${ret.data.error}`, ['api'])
            }

            return (ret.data)
        } catch (e) {
            return ({ error: e.message, data: options.data })
        }
    }

    kernel.api.post = async (resource, data, opts = {}) => {
        const url = `${kernel.config.central}${resource}`
        const deviceToken = localStorage.getItem("deviceToken")

        kernel.debug(`POST ${resource} > ${url}`, ['api'])
        const options = {
            url: url,
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                // "User-Agent": `DOHzel/${pack.version}`
            },
            data
        }

        if (opts.userRequest === true && kernel?.session?.current)
            options.headers.Authorization = kernel.session.current
        else if (opts.deviceRequest === true && deviceToken)
            options.headers.Authorization = deviceToken
        else if (kernel.api.token)
            options.headers.Authorization = kernel.api.token

        try {
            const ret = await axios(options)
            if (ret.data.error) {
                kernel.debug(`Network error: ${ret.data.error}`, ['api'])
            }
            return (ret.data)
        } catch (e) {
            return ({ error: e.message, data: options.data })
        }
    }

    kernel.api.head = async (resource, opts = {}) => {
        const url = `${kernel.config.central}${resource}`
        const deviceToken = localStorage.getItem("deviceToken")

        kernel.debug(`HEAD ${resource} > ${url}`, ['api'])
        const options = {
            url: url,
            method: "HEAD",
            headers: {
                // "User-Agent": `DOHzel/${pack.version}`,
            }
        }

        if (opts.userRequest === true && kernel?.session?.current)
            options.headers.Authorization = kernel.session.current
        else if (opts.deviceRequest === true && deviceToken)
            options.headers.Authorization = deviceToken
        else if (kernel.api.token)
            options.headers.Authorization = kernel.api.token

        try {
            const ret = await axios(options)
            if (ret.data.error) {
                kernel.debug(`Network error: ${ret.data.error}`, ['api'])
            }

            return (ret)
        } catch (e) {
            return ({ error: e.message, data: options.data })
        }
    }

    kernel.api.checkVersion = async () => {
        kernel.log("Checking API versions");
        const ret = await kernel.api.get('/version')
        if (!ret.error) {
            const currentApiVersion = localStorage.getItem("apiVersion")
            if (currentApiVersion !== ret.data.software) {
                localStorage.setItem("apiVersion", ret.data.software)
                window.location.reload()
            }
            kernel.api.version = currentApiVersion
        }
        return (ret.data)
    }
    await kernel.api.checkVersion()

    // var _mainBeat = false
    kernel.api.beat = async (singleShot) => {
        // if (singleShot !== true && _mainBeat === true)
        //     return
        // _mainBeat = true

        const savedToken = localStorage.getItem("deviceToken")
        if (savedToken) {

            /* if (kernel.device.desktopApp === true)
                kernel.device.desktop.setToken(savedToken) */

            const payload = {
                device: kernel.device,
                deviceType: kernel.device.iosApp ? 'appleiOS' : (kernel.device.androidApp ? 'googleAndroid' : 'browser')
            }
            kernel.api.token = savedToken

            // load APN and co
            if (kernel.device.iosApp === true && kernel.device?.ios?.settingsGetDeviceInfo) {
                const gdi = await kernel.device.ios.settingsGetDeviceInfo()
                if (gdi)
                    kernel.device.apple = gdi.data
            }

            if (kernel.device.androidApp === true && kernel.device?.android?.settingsGetDeviceInfo) {
                const gdi = await kernel?.device?.android?.settingsGetDeviceInfo()
                if (gdi)
                    kernel.device.google = gdi.data
            }

            // send the beat
            // in case of using desktop version the beat passes via the proxy
            const caller = async () => kernel.device.desktopApp === true ?
                kernel.device.desktop.getBeat()
                :
                kernel.api.post('/dohzel/device/beat', payload)
            const ret = await caller()
            if (!ret.error) {

                // Special handle for Apple
                // initialize DOH
                if (ret.data.doh !== kernel.api.doh && kernel.device.iosApp === true)
                    await kernel.device.ios.settingsSetDoH(ret.data.doh)

                // fill structs
                kernel.api.doh = ret.data.doh
                kernel.api.dot = `${ret.data.dohToken}.${kernel.config.dot}`
                kernel.api.dohToken = ret.data.dohToken
                kernel.api.mode = ret.data.mode
                kernel.api.stripe = ret.data.stripe
                kernel.api.lastContact = ret.data.lastContact
                kernel.api.list = ret.data.list
                kernel.api.listInfo = {}
                kernel.api.context = ret.data.context
                kernel.api.license = ret.data.license
                kernel.api.deviceManagement = ret.data.deviceManagement
                kernel.api.security = ret.data.security
                kernel.api.securityLocked = ret.data.securityLocked
                kernel.api.policy = ret.data.policy
                kernel.api.logToProfile = ret.data.logToProfile
                kernel.api.profileName = ret.data.profileName
                kernel.api.deviceName = ret.data.deviceName

                if (kernel.api?.context?.lists) {
                    for (var item of kernel.api.context.lists) {
                        if (item.id === kernel.api.list) {
                            kernel.api.listInfo = item
                            break
                        }
                    }
                }
            }
        }
        

        if (singleShot !== true)
            setTimeout(kernel.api.beat, 10 * 1000)
    }
    await kernel.api.beat()


    kernel.api.getStats = async (type) => {
        async function fetch() {
            const ret = await kernel.api.get(`/dohzel/device/stats/${type}`)
            if (!ret || ret.error) return (null)

            localStorage.setItem(`stats.${type}`, JSON.stringify(ret))
            return (ret)
        }

        try {
            const savedToken = JSON.parse(localStorage.getItem(`stats.${type}`))
            fetch()
            if (savedToken) return (savedToken)
        } catch (e) {
            return (fetch())
        }
    }
}

